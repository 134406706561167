<div id="bgimg" class="bgimg">
<div *ngIf="!isMobileResolution">
    <div style="text-align: left;">
      <div style="padding-top: 5px;padding-left: 5%;"><h2><strong>Informationen zur Rückmeldung einer Markierung bzw. eines markierten Fisches</strong></h2></div>
      <table cellpadding="5" cellspacing="0" style="width: 100%" border="0">
        <tbody>
        <tr>
          <td><br>
      <table cellpadding="5" cellspacing="0" style="width: 100%" border="0">
        <thead>
        <th><strong>Fischart</strong></th><th></th><th><strong>Ort</strong></th><th></th><th><strong>Gewässer</strong></th>
        </thead>
        <tbody>
        <tr>
          <td class="bordered" style="width: 20%" >
            <mat-form-field>
              <input matInput placeholder="Wählen Sie eine Fischart" aria-label="Fisch" [(ngModel)]="fischtyp" (ngModelChange)="changeFisch()" [formControl]='fischeControl' [matAutocomplete]="auto2" >
              <mat-autocomplete #auto2="matAutocomplete" [displayWith]="displayFn">
                <mat-option *ngIf="isLoading" class="is-loading">Loading...</mat-option>
                <ng-container *ngIf="!isLoading">
                  <mat-option *ngFor="let fisch of filteredFische" [value]="fisch">
                    <span>{{ fisch.name }}</span>
                  </mat-option>
                </ng-container>
              </mat-autocomplete>
            </mat-form-field>
          </td>
          <td style="width: 20%" ></td>
          <td class="bordered" style="width: 20%" ><mat-form-field><input id="gefunden.ort23" type="text" placeholder="Geben Sie den Fundort ein"  matInput [(ngModel)]="gefunden.fort" /></mat-form-field></td>
          <td style="width: 20%" ></td>
          <td class="bordered" style="width: 20%">
          <mat-form-field>
            <input matInput placeholder="Wählen Sie ein Gewässer" aria-label="Fluss" [(ngModel)]="fluss" (ngModelChange)="changeFluss()" [formControl]='fluesseControl' [matAutocomplete]="auto" >
            <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
              <mat-option *ngIf="isLoading" class="is-loading">Loading...</mat-option>
              <ng-container *ngIf="!isLoading">
                <mat-option *ngFor="let fluss of filteredFluesse" [value]="fluss">
                  <span>{{ fluss.name }}</span>
                </mat-option>
              </ng-container>
            </mat-autocomplete>
          </mat-form-field>
        </td>
        </tr>
        </tbody>
      </table><br>

    <table cellpadding="5" cellspacing="0" style="width: 100%" border="0">
<thead>
<th><strong>Sendertyp</strong></th><th></th><th><strong>Länge [cm]</strong></th><th></th><th><strong>Gewicht [g]</strong></th>
</thead>
<tbody>
<tr>
  <td class="bordered" style="width: 20%">
  <div *ngIf="sender.id==0">
    <mat-form-field>
      <mat-select placeholder="Wählen Sie den Sendertyp" [(ngModel)]="gefunden.sid" name="send">
        <mat-option *ngFor="let send of senders" [value]="send.id">
          {{send.name}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div *ngIf="sender.id!=0">
    {{sender.name}}
  </div></td>
  <td style="width: 20%" ></td>
  <td class="bordered" style="width: 20%"><mat-form-field><input id="gefunden.gewict2" type="number" placeholder="Geben Sie die Länge an" matInput [(ngModel)]="gefunden.laenge" /></mat-form-field></td>
  <td style="width: 20%" ></td>
<td class="bordered" style="width: 20%"><mat-form-field><input id="gefunden.laenge2" type="number" placeholder="Geben Sie das Gewicht an" matInput [(ngModel)]="gefunden.gewicht" /></mat-form-field></td>
</tr>
</tbody>
</table><br>
      <table cellpadding="5" cellspacing="0" style="width: 100%" border="0">
        <thead>
        <th><strong>Datum</strong></th><th></th><th><strong>Bemerkungen und ggf. Code ID auf dem Sender</strong></th>
        </thead>
        <tbody>
        <tr>
          <td class="bordered" style="width: 20%"><mat-form-field><input id="gefunden.dat2" type="date" placeholder="Wählen Sie das Datum" matInput [(ngModel)]="gefunden.datum" /></mat-form-field></td>
          <td style="width: 20%" ></td>
          <td style="width: 60%" class="bordered"><textarea [matTextareaAutosize] matInput id="gefunden.notiz2"  [(ngModel)]="gefunden.notiz"></textarea></td>

        </tr>
        </tbody>
      </table>
          <br></td></tr></tbody></table><br>
<!--<div *ngIf="gefunden.fid!=0">
<table cellpadding="5" cellspacing="0" style="width: 100%" borderthead>
<thead><th></th><th><strong>Code auf dem Sender</strong></th><th></th>
</thead>
<tbody>
<tr>
  <td style="width: 30%" ></td>
  <td class="bordered" style="width: 20%">
  <mat-form-field>
    <input matInput placeholder="Wählen Sie den Code" aria-label="Code" [(ngModel)]="fisch" (ngModelChange)="changeCode()" [formControl]='senderControl' [matAutocomplete]="auto3" >
    <mat-autocomplete #auto3="matAutocomplete" [displayWith]="displayFn">
      <mat-option *ngIf="isLoading" class="is-loading">Loading...</mat-option>
      <ng-container *ngIf="!isLoading">
        <mat-option *ngFor="let fisch of filteredCode" [value]="fisch">
          <span>{{ fisch.name }}</span>
        </mat-option>
      </ng-container>
    </mat-autocomplete>-->
    <!--<input id="gefunden.fischid" type="number" placeholder="Geben Sie den Code an" matInput [(ngModel)]="gefunden.fischid" />-->
  <!--</mat-form-field>
</td>
  <td style="width: 30%" ></td>

</tr>
</tbody>
</table></div><br>-->
    </div></div>

<div *ngIf="isMobileResolution">
  <div style="text-align: left;">
    <div style="padding-top: 5px;padding-left: 15%;"><h2><strong>Informationen zur Rückmeldung einer Markierung bzw. eines markierten Fisches</strong></h2></div>
    <table cellpadding="5" cellspacing="0" style="width: 100%" border="0">
      <tbody>
      <tr>
        <td><br>
          <table cellpadding="5" cellspacing="0" style="width: 100%" border="0">
            <thead>
            <th></th><th><strong>Fischart</strong></th><th></th>
            </thead>
            <tbody>
            <tr>
              <td style="width: 10%;padding-right: 0.5em"></td>
              <td class="bordered" style="width: 80%" >
                <mat-form-field>
                  <input matInput placeholder="Wählen Sie eine Fischart" aria-label="Fisch" [(ngModel)]="fischtyp" (ngModelChange)="changeFisch()" [formControl]='fischeControl' [matAutocomplete]="auto5" >
                  <mat-autocomplete #auto5="matAutocomplete" [displayWith]="displayFn">
                    <mat-option *ngIf="isLoading" class="is-loading">Loading...</mat-option>
                    <ng-container *ngIf="!isLoading">
                      <mat-option *ngFor="let fisch of filteredFische" [value]="fisch">
                        <span>{{ fisch.name }}</span>
                      </mat-option>
                    </ng-container>
                  </mat-autocomplete>
                </mat-form-field>
              </td>
              <td style="width: 10%;padding-left: 0.5em"></td>
            </tr>
            </tbody>
          </table><br>
          <table cellpadding="5" cellspacing="0" style="width: 100%" border="0">
            <thead>
            <th></th><th><strong>Datum</strong></th><th></th>
            </thead>
            <tbody>
            <tr>
              <td style="width: 10%;padding-right: 0.5em"></td>
              <td class="bordered" style="width: 80%" >
                <mat-form-field><input type="date" placeholder="Wählen Sie das Datum" matInput [(ngModel)]="gefunden.datum"></mat-form-field></td>
              <td style="width: 10%;padding-left: 0.5em"></td>
            </tr>
            </tbody>
          </table><br>
          <table cellpadding="5" cellspacing="0" style="width: 100%" border="0">
            <thead>
            <th></th><th><strong>Gewässer</strong></th><th></th>
            </thead>
            <tbody>
            <tr>
              <td style="width: 10%;padding-right: 0.5em"></td>
              <td class="bordered" style="width: 80%" >
                <mat-form-field>
                  <input matInput placeholder="Wählen Sie ein Gewässer" aria-label="Fluss" [(ngModel)]="fluss" (ngModelChange)="changeFluss()" [formControl]='fluesseControl' [matAutocomplete]="auto6" >
                  <mat-autocomplete #auto6="matAutocomplete" [displayWith]="displayFn">
                    <mat-option *ngIf="isLoading" class="is-loading">Loading...</mat-option>
                    <ng-container *ngIf="!isLoading">
                      <mat-option *ngFor="let fluss of filteredFluesse" [value]="fluss">
                        <span>{{ fluss.name }}</span>
                      </mat-option>
                    </ng-container>
                  </mat-autocomplete>
                </mat-form-field>
              </td>
              <td style="width: 10%;padding-left: 0.5em"></td>
            </tr>
            </tbody>
          </table><br>
          <table cellpadding="5" cellspacing="0" style="width: 100%" border="0">
            <thead>
            <th></th><th><strong>Ort</strong></th><th></th>
            </thead>
            <tbody>
            <tr>
              <td style="width: 10%" ></td>
              <td style="width: 80%" class="bordered"><mat-form-field><input id="gefunden.ort123" type="text" placeholder="Geben den Fundort ein"  matInput [(ngModel)]="gefunden.fort" /></mat-form-field></td>
              <td style="width: 10%" ></td>
            </tr>
            </tbody>
          </table><br>
          <table cellpadding="5" cellspacing="0" style="width: 100%" border="0">
            <thead>
            <th></th><th><strong>Sendertyp</strong></th><th></th>
            </thead>
            <tbody>
            <tr>
              <td style="width: 10%;padding-right: 0.5em"></td>
              <td class="bordered" style="width: 80%" >
                <div *ngIf="sender.id==0">
                  <mat-form-field>
                    <mat-select placeholder="Wählen Sie den Sendertyp" [(ngModel)]="gefunden.sid" name="send">
                      <mat-option *ngFor="let send of senders" [value]="send.id">
                        {{send.name}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <div *ngIf="sender.id!=0">
                  {{sender.name}}
                </div></td>
              <td style="width: 10%;padding-left: 0.5em"></td>
            </tr>
            </tbody>
          </table><br>

          <table cellpadding="5" cellspacing="0" style="width: 100%" border="0">
            <thead>
            <th></th><th><strong>Länge [cm]</strong></th><th></th>
            </thead>
            <tbody>
            <tr>

              <td style="width: 10%;padding-right: 0.5em"></td>
              <td class="bordered" style="width: 80%" >
                <mat-form-field><input id="gefunden.gew" type="number" placeholder="Geben Sie das Gewicht an" matInput [(ngModel)]="gefunden.laenge" /></mat-form-field>
              <td style="width: 10%;padding-left: 0.5em"></td>
            </tr>
            </tbody>
          </table><br>
          <table cellpadding="5" cellspacing="0" style="width: 100%" border="0">
            <thead>
            <th></th><th><strong>Gewicht [g]</strong></th><th></th>
            </thead>
            <tbody>
            <tr>
              <td style="width: 10%;padding-right: 0.5em"></td>
              <td class="bordered" style="width: 80%" >
                <mat-form-field><input id="gefunden.laenge" type="number" placeholder="Geben Sie das Gewicht an" matInput [(ngModel)]="gefunden.gewicht" /></mat-form-field>
              <td style="width: 10%;padding-left: 0.5em"></td>
            </tr>
            </tbody>
          </table><br>
          <table cellpadding="5" cellspacing="0" style="width: 100%" border="0">
            <thead>
            <th></th><th><strong>Bemerkungen und ggf. Code ID auf dem Sender</strong></th><th></th>
            </thead>
            <tbody>
            <tr>
              <td style="width: 10%" ></td>

              <td style="width: 80%" class="bordered"><textarea [matTextareaAutosize] matInput id="notiz"  [(ngModel)]="gefunden.notiz"></textarea></td>
              <td style="width: 10%" ></td>

            </tr>
            </tbody>
          </table>
          <br></td></tr></tbody></table><br>
    <!--<div *ngIf="gefunden.fid!=0">
      <table cellpadding="5" cellspacing="0" style="width: 100%" borderthead>
        <thead><th></th><th><strong>Code auf dem Sender</strong></th><th></th>
        </thead>
        <tbody>
        <tr>
          <td style="width: 10%" ></td>
          <td class="bordered" style="width: 80%">
            <mat-form-field>
              <input matInput placeholder="Wählen Sie den Code" aria-label="Code" [(ngModel)]="fisch" (ngModelChange)="changeCode()" [formControl]='senderControl' [matAutocomplete]="auto3" >
    <mat-autocomplete #auto3="matAutocomplete" [displayWith]="displayFn">
      <mat-option *ngIf="isLoading" class="is-loading">Loading...</mat-option>
      <ng-container *ngIf="!isLoading">
        <mat-option *ngFor="let fisch of filteredCode" [value]="fisch">
          <span>{{ fisch.name }}</span>
        </mat-option>
      </ng-container>
    </mat-autocomplete>-->
              <!--<input id="gefunden.fischid2" type="number" placeholder="Geben Sie den Code an" matInput [(ngModel)]="gefunden.fischid" />-->
           <!-- </mat-form-field>
          </td>
          <td style="width: 10%" ></td>

        </tr>
        </tbody>
      </table></div><br>-->
  </div></div>

      <div style="padding-top: 5px;padding-left: 5%;"><h2><strong>Informationen zum Fang- resp. Nachweisort</strong></h2></div>
      <div style="padding-top: 5px;padding-left: 5%;">Bitte markieren Sie den Fangort auf der Karte.</div>

  <table cellpadding="5" cellspacing="0" style="width: 100%" border="0">
        <tbody>
        <tr>
          <td>
      <table cellpadding="5" cellspacing="0" style="width: 100%" border="0">
        <tbody>
        <tr>
          <td id="map-tab" class="map-tab">
<br>
      <div id="map-frame" class="map-frame">
        <div id="map"></div>
      </div>

            <div *ngIf="!isMobileResolution"> <br><br> <br><br><br><br><br><br><br> <br><br><br><br><br><br><br> </div>
            <br><br><br><br><br><br><br><br><br><br><br><br><br><br><br>
          </td>
          <!--<td id="cord-tab" class="cord-tab">
          <div *ngIf="!isMobileResolution">
          <table cellpadding="5" cellspacing="0" style="width: 100%" border="0">
    <thead>
    <th></th><th><strong>Breitengrad / Latitude</strong></th>
    </thead>
    <tbody>
    <tr><td></td><td style="width: 35%" class="bordered">{{gefunden.ycord}}</td><td style="width: 35%"></td>
    </tr>
    </tbody>
  </table><br>
          <table cellpadding="5" cellspacing="0" style="width: 100%" border="0">
            <thead>
            <th></th><th><strong>Längengrad / Longitude</strong></th>
            </thead>
            <tbody>
            <tr><td></td><td style="width: 35%" class="bordered">{{gefunden.xcord}}</td><td style="width: 35%">

            </tr>
            </tbody>
          </table>
          </div>
          </td>
        </tr>
        <tr><td>
          <div *ngIf="isMobileResolution">
          <table cellpadding="5" cellspacing="0" style="width: 100%" border="0">
          <thead>
          <th></th><th><strong>Breitengrad / Latitude</strong></th><th></th>
          </thead>
          <tbody>
          <tr>
            <td style="width: 10%" ></td>
            <td style="width: 80%" class="bordered">{{gefunden.ycord}}</td>
            <td style="width: 10%" ></td>
          </tr>
          </tbody>
        </table><br>
          <table cellpadding="5" cellspacing="0" style="width: 100%" border="0">
            <thead>
            <th></th><th><strong>Längengrad / Longitude</strong></th><th></th>
            </thead>
            <tbody>
            <tr>
              <td style="width: 10%" ></td>
              <td style="width: 80%" class="bordered">{{gefunden.xcord}}</td>
              <td style="width: 10%" ></td>
            </tr>
            </tbody>
          </table>
          </div>
        </td></tr>
        --></tr></tbody>
      </table>
          </td>
        </tr>
        </tbody>
      </table>
         <br><br>

  <div *ngIf="!isMobileResolution">
    <div style="text-align: left;">
    <div style="padding-top: 5px;padding-left: 5%;"><h2><strong>Informationen zur Kontaktaufnahme</strong></h2></div>
      <table cellpadding="5" cellspacing="0" style="width: 100%" border="0">
        <tbody>
        <tr>
          <td><br>
            <table cellpadding="5" cellspacing="0" style="width: 100%" border="0">
              <thead>
              <th><strong>Name</strong></th><th></th><th><strong>Vorname</strong></th><th></th><th><strong>E-Mail</strong></th>
              </thead>
              <tbody>
              <tr>
                <td class="bordered" style="width: 20%" ><mat-form-field><input id="gefunden.name2" placeholder="Geben Sie Ihren Nachnamen an" matInput type="text" [(ngModel)]="finder.name" /></mat-form-field></td>
                <td style="width: 20%" ></td>
                <td class="bordered" style="width: 20%" ><mat-form-field><input id="gefunden.vorname2" placeholder="Geben Sie Ihren Vornamen an" matInput type="text" [(ngModel)]="finder.vorname" /></mat-form-field></td>
                <td style="width: 20%" ></td>
                <td class="bordered" style="width: 20%" ><mat-form-field><input id="gefunden.email2" placeholder="Geben Sie Ihren E-Mail-Adresse an" matInput type="text" [(ngModel)]="finder.email" /></mat-form-field></td>
              </tr>
              </tbody>
            </table><br>
            <table cellpadding="5" cellspacing="0" style="width: 100%" border="0">
              <thead>
              <th><strong>PLZ</strong></th><th></th><th><strong>Ort</strong></th><th></th><th><strong>Straße u. Nummer</strong></th>
              </thead>
              <tbody>
              <tr>
                <td class="bordered" style="width: 20%" ><mat-form-field><input id="gefunden.plz2" placeholder="Geben Sie Ihre PLZ an" matInput type="text" [(ngModel)]="finder.plz" /></mat-form-field></td>
                <td style="width: 20%" ></td>
                <td class="bordered" style="width: 20%" ><mat-form-field><input id="gefunden.ort2" placeholder="Geben Sie Ihren Wohnort an" matInput type="text" [(ngModel)]="finder.ort" /></mat-form-field></td>
                <td style="width: 20%" ></td>
                <td class="bordered" style="width: 20%" ><mat-form-field><input id="gefunden.strasse2" placeholder="Geben Sie Ihre Straße an" matInput type="text" [(ngModel)]="finder.strasse" /></mat-form-field></td>
              </tr>
              </tbody>
            </table><br>
          </td>
        </tr>
        </tbody>
      </table>

      <br>
      <div style="padding-top: 5px;padding-left: 5%;"><h2><strong>Bankverbindung für den Finderlohn</strong></h2></div>
      <table cellpadding="5" cellspacing="0" style="width: 100%" border="0">
        <tbody>
        <tr>
          <td><br>
            <table cellpadding="5" cellspacing="0" style="width: 100%" border="0">
              <thead>
              <th><strong>Bank</strong></th><th></th><th><strong>IBAN</strong></th><th></th><th><strong>BIC</strong></th>
              </thead>
              <tbody>
              <tr>
                <td class="bordered" style="width: 20%" ><mat-form-field><input id="gefunden.bank2" placeholder="Geben Sie den Namen Ihrer Bank an" matInput type="text" [(ngModel)]="finder.bank" /></mat-form-field></td>
                <td style="width: 20%" ></td>
                <td class="bordered" style="width: 20%" ><mat-form-field><input id="gefunden.iban2" placeholder="Geben Sie Ihre IBAN an" matInput type="text" [(ngModel)]="finder.iban" /></mat-form-field></td>
                <td style="width: 20%" ></td>
                <td class="bordered" style="width: 20%" ><mat-form-field><input id="gefunden.bic2" placeholder="Geben Sie Ihre BIC an" matInput type="text" [(ngModel)]="finder.bic" /></mat-form-field></td>
              </tr>
              </tbody>
            </table><br>
          </td>
        </tr>
        </tbody>
      </table>
      <br><div style="padding-top: 5px;padding-left: 10%;"><h2><strong>{{info}}</strong></h2></div><br>
   <table cellpadding="5" cellspacing="0" style="width: 100%" border="0"><tr>
           <td width="50%" style="text-align: right;"><div *ngIf="pdf==0 && gefunden.fid!=0">
              <button mat-raised-button color="primary" size="large" (click)="save()"><div style="font-size: 2em">Daten übertragen</div></button>
    </div></td><td width="50%" style="text-align: left;">
           <div *ngIf="pdf!=0">
              &nbsp;<button mat-raised-button color="accent" size="large"><a href="./api/pdf.php?pid={{pdf}}&finder={{finder.name}}" style="text-decoration:none" target="blank">
           <div style="font-size: 2em;color: white">PDF erstellen</div></a></button>
           </div></td></tr></table></div></div><br>

<div *ngIf="isMobileResolution">
  <div style="text-align: left;">
    <div style="padding: 5px;text-align: left;"><h2><strong>Informationen zur Kontaktaufnahme</strong></h2></div>
    <table cellpadding="5" cellspacing="0" style="width: 100%" border="0">
      <tbody>
      <tr>
        <td><br>
          <table cellpadding="5" cellspacing="0" style="width: 100%" border="0">
            <thead>
            <th></th><th><strong>Name</strong></th><th></th>
            </thead>
            <tbody>
            <tr>
              <td style="width: 10%" ></td>
              <td style="width: 80%" class="bordered"><mat-form-field><input id="gefunden.name" placeholder="Geben Sie Ihren Nachnamen an" matInput type="text" [(ngModel)]="finder.name" /></mat-form-field></td>
              <td style="width: 10%" ></td>
            </tr>
            </tbody>
          </table><br>
          <table cellpadding="5" cellspacing="0" style="width: 100%" border="0">
            <thead>
            <th></th><th><strong>Vorname</strong></th><th></th>
            </thead>
            <tbody>
           <tr>
              <td style="width: 10%" ></td>
              <td style="width: 80%" class="bordered"><mat-form-field><input id="gefunden.vorname" placeholder="Geben Sie Ihren Vornamen an" matInput type="text" [(ngModel)]="finder.vorname" /></mat-form-field></td>
              <td style="width: 10%" ></td>
            </tr>
            </tbody>
          </table><br>
          <table cellpadding="5" cellspacing="0" style="width: 100%" border="0">
            <thead>
            <th></th><th><strong>E-Mail</strong></th><th></th>
            </thead>
            <tbody>
            <tr>
              <td style="width: 10%" ></td>
              <td style="width: 80%" class="bordered"><mat-form-field><input id="gefunden.email" placeholder="Geben Sie Ihre E-Mail-Adresse an" matInput type="text" [(ngModel)]="finder.email" /></mat-form-field></td>
              <td style="width: 10%" ></td>
            </tr>
            </tbody>
          </table><br>
          <table cellpadding="5" cellspacing="0" style="width: 100%" border="0">
            <thead>
            <th></th><th><strong>PLZ</strong></th><th></th>
            </thead>
            <tbody>
            <tr>
              <td style="width: 10%" ></td>
              <td style="width: 80%" class="bordered"><mat-form-field><input id="gefunden.plz" placeholder="Geben Sie Ihre PLZ an" matInput type="text" [(ngModel)]="finder.plz" /></mat-form-field></td>
              <td style="width: 10%" ></td>
            </tr>
            </tbody>
          </table><br>
          <table cellpadding="5" cellspacing="0" style="width: 100%" border="0">
            <thead>
            <th></th><th><strong>Ort</strong></th><th></th>
            </thead>
            <tbody>
            <tr>
              <td style="width: 10%" ></td>
              <td style="width: 80%" class="bordered"><mat-form-field><input id="gefunden.ort" placeholder="Geben Sie Ihren Wohnort an" matInput type="text" [(ngModel)]="finder.ort" /></mat-form-field></td>
              <td style="width: 10%" ></td>
            </tr>
            </tbody>
          </table><br>
          <table cellpadding="5" cellspacing="0" style="width: 100%" border="0">
            <thead>
            <th></th><th><strong>Straße u. Nummer</strong></th><th></th>
            </thead>
            <tbody>
            <tr>
              <td style="width: 10%" ></td>
              <td style="width: 80%" class="bordered"><mat-form-field><input id="gefunden.strasse" placeholder="Geben Sie Ihre Straße an" matInput type="text" [(ngModel)]="finder.strasse" /></mat-form-field></td>
              <td style="width: 10%" ></td>
            </tr>
            </tbody>
          </table><br>
        </td>
      </tr>
      </tbody>
    </table>
    <br>
    <div style="padding: 5px;text-align: left;"><h2><strong>Bankverbindung für den Finderlohn</strong></h2></div>
    <table cellpadding="5" cellspacing="0" style="width: 100%" border="0">
      <tbody>
      <tr>
        <td><br>
          <table cellpadding="5" cellspacing="0" style="width: 100%" border="0">
            <thead>
            <th></th><th><strong>Bank</strong></th><th></th>
            </thead>
            <tbody>
            <tr>
              <td style="width: 10%" ></td>
              <td style="width: 80%" class="bordered"><mat-form-field><input id="gefunden.bank" placeholder="Geben Sie den Namen Ihrer Bank an" matInput type="text" [(ngModel)]="finder.bank" /></mat-form-field></td>
              <td style="width: 10%" ></td>
            </tr>
            </tbody>
          </table><br>
                <table cellpadding="5" cellspacing="0" style="width: 100%" border="0">
                  <thead>
                  <th></th><th><strong>IBAN</strong></th><th></th>
                  </thead>
                  <tbody>
                  <tr>
                    <td style="width: 10%" ></td>
                    <td style="width: 80%" class="bordered"><mat-form-field><input id="gefunden.iban" placeholder="Geben Sie Ihre IBAN an" matInput type="text" [(ngModel)]="finder.iban" /></mat-form-field></td>
                    <td style="width: 10%" ></td>
                  </tr>
                  </tbody>
                </table><br>
                      <table cellpadding="5" cellspacing="0" style="width: 100%" border="0">
                        <thead>
                        <th></th><th><strong>BIC</strong></th><th></th>
                        </thead>
                        <tbody>
                        <tr>
                          <td style="width: 10%" ></td>
                          <td style="width: 80%" class="bordered"><mat-form-field><input id="gefunden.bic" placeholder="Geben Sie Ihre BIC an" matInput type="text" [(ngModel)]="finder.bic" /></mat-form-field></td>
                          <td style="width: 10%" ></td>
                        </tr>
                        </tbody>
                      </table><br>
        </td>
      </tr>
      </tbody>
    </table>
    <br><strong>{{info}}</strong><br>
    <table cellpadding="5" cellspacing="0" style="width: 100%" border="0"><tr>
      <td width="100%" style="text-align: center;"><div *ngIf="pdf==0 && gefunden.fid!=0">
        <button mat-raised-button color="primary" size="large" (click)="save()"><div style="font-size: 2em">Daten übertragen</div></button>
      </div></td></tr></table>
    <table cellpadding="5" cellspacing="0" style="width: 100%" border="0"><tr>
      <td width="100%" style="text-align: center;">
      <div *ngIf="pdf!=0">
        &nbsp;<button mat-raised-button color="accent" size="large"><a href="./api/pdf.php?pid={{pdf}}&finder={{finder.name}}" style="text-decoration:none" target="blank">
        <div style="font-size: 2em;color: white">PDF erstellen</div></a></button>
      </div></td></tr></table></div></div></div><br>








